import 'bootstrap/dist/css/bootstrap.min.css'

import '../css/main.css'
import 'regenerator-runtime/runtime'

import './icons'
import './check-updates'
import { prepareForm, gopdf } from './form-util'
import { warnFacebookBrowserUserIfNecessary } from './facebook-util'
import { addVersion } from './util'
import { createForm } from './form'
import { locate } from './geolocation'
import Cookies from 'js-cookie'
import { $, $$, downloadBlob } from './dom-utils'

// warnFacebookBrowserUserIfNecessary()
if(!Cookies.get('placeofbirth')){
    $('#explications').classList.remove('d-none')
}
locate().then((location) => {
  createForm()
  prepareForm(location)
  addVersion(process.env.VERSION)
  const formInputs = $$('#form-profile input')
  const snackbar = $('#snackbar')
  const reasonInputs = [...$$('input[name="field-reason"]')]
  const reasonFieldset = $('#reason-fieldset')
  const reasonAlert = reasonFieldset.querySelector('.msg-alert')
  const releaseDateInput = $('#field-datesortie')
  if(Cookies.get('placeofbirth')){
    gopdf(formInputs, reasonInputs)
  }
})
