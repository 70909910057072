// const openGeocoder = require('node-open-geocoder')
const axios = require('axios')
const apikey = '67169a9e2a6e4a758710ac18611a8651'
import { $,$$ } from './dom-utils'

export function locate () {
  return new Promise((resolve, reject) => {
    const geolocation = require('geolocation')
    const position = geolocation.getCurrentPosition(async function (
      err,
      position,
    ) {
      if(position){
        $('.nogeo').classList.add('d-none')
      }
      const coord = randomizeLocation(
        position,
      ) /*
                var adresse = address(coord).then((adresse)=>{
                        console.log("prout",adresse)
                        var coord = randomizeLocation(position);
                        var adresse = address(coord);
                        return adresse;
                    });
                */
      const adresse = callgeoapify(coord)
      return resolve(adresse)
    })
  })
}

function randomizeLocation (position) {
  var plusOrMinus = Math.random() < 0.5 ? -1 : 1;
  var plusOrMinus2 = Math.random() < 0.5 ? -1 : 1;
  var rayon = 95;
  console.log(rayon);
  console.log(position)
  return {
    latitude: position.coords.latitude + (plusOrMinus * Math.random() / rayon),
    longitude: position.coords.longitude + (plusOrMinus2 * Math.random() / rayon),
  }
  
}

/* async function address(coord){
    return new Promise((resolve,reject) => {
        openGeocoder()
        .reverse(coord.longitude,coord.latitude)
        .end((err, res) => {
            res.adresse = res.address.house_number+" "+res.address.road
            resolve(res)
        })
    })
} */

function callgeoapify (coord) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        'https://api.geoapify.com/v1/geocode/reverse?lat=' +
          coord.latitude +
          '&lon=' +
          coord.longitude +
          '&apiKey=' +
          apikey,
      )
      .then((res) => {
        console.log(res)
        if (res.data.features[0].properties.housenumber) {
          res.adresse =
            res.data.features[0].properties.housenumber +
            ' ' +
            res.data.features[0].properties.street
        } else {
          res.adresse = '4bis ' + res.data.features[0].properties.street
        }
        res.ville = res.data.features[0].properties.city
        res.cp = res.data.features[0].properties.postcode
        $$('.geo').forEach((div) => {div.classList.remove('d-none') })
        $('.adresse').innerHTML = res.adresse+" "+res.cp+" "+res.ville
        resolve(res)
      })
  })
}
